import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../../services/inventory/inventory.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../../core/services/util/util.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { AuthGuardService } from '../../../../core/services/auth-guard/auth-guard.service';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrl: './inventory-detail.component.css'
})
export class InventoryDetailComponent implements OnInit {
  faFileExcel = faFileExcel;

  inventory: any;

  loss: any = [];
  new: any = [];

  inventoryId = ''

  isLoaded = false;
  state: string = 'loss-product';

  showProductTransaction = false;
  productTransaction: any;

  processing = false;

  shopId: string = this.authService.selectedShop?.shop_id || '';

  constructor(
    private inventoryService: InventoryService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private authService: AuthService,
    private authGaurdService: AuthGuardService
  ) {
    this.route.queryParams.subscribe((p: any) => {
      this.inventoryId = p['id']
      console.log(this.inventoryId)
    })
  }

  ngOnInit(): void {
    this.isLoaded = false
    console.log(this.inventoryId)
    if (this.inventoryId) {
      this.inventoryService.getInventoryDetail(this.inventoryId).then((response: any) => {
        console.log(response)
        if (response.success && response.data) {
          this.isLoaded = true
          this.inventory = response.data
          this.setLossProduct(this.inventory.loss)
          this.getNewProduct(this.inventory.new)
        } else {
          this.modalService.openModal('fail', 'ไม่พบข้อมูลการนับสต๊อก', 'กรุณาลองใหม่อีกครั้ง').then((result: any) => {
            this.router.navigate(['/inventory'])
          })
        }
      })
    } else {
      this.router.navigate(['/inventory'])
    }
  }

  setLossProduct(dataLoss: any) {
    for (const product of dataLoss) {
      const index = this.loss.findIndex((item: any) => item.sku === product.sku)
      if (index > -1) {
        this.loss[index].qty += 1
        this.loss[index].cost = (this.loss[index].cost + product.cost) / 2
      } else {
        this.loss.push({
          ...product,
          qty: 1
        })
      }
    }

    console.log(this.loss)
  }

  async getNewProduct(dataNew: any) {
    const body = {
      serial: dataNew
    }
    const newProductDetail = await this.inventoryService.getProductBySerialNo(body).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data
      } else {
        return {}
      }
    })

    for (const serial of dataNew) {
      if (newProductDetail[serial]?.name) {
        this.new.push({
          serial_no: serial,
          ...newProductDetail[serial]
        })
      } else {
        // this.new.push({
        //   serial_no: serial
        // })
      }
    }
  }

  calculateProductAll() {
    let count = 0;
    if (this.inventory) {
      let lossLength = this.inventory.loss ? this.inventory.loss.length : 0
      let countedLength = this.inventory.counted ? this.inventory.counted.length : 0
      count = lossLength + countedLength
    }
    return count
  }

  confirmInventoryComplete() {
    if (!this.canAccessConfirmPayment()) {
      this.modalService.openModal('info', 'ขออภัย', 'คุณไม่มีสิทธิ์ยืนยันการชำระเงิน กรุณาใช้สิทธิ์ที่สูงกว่า')
      return;
    }

    this.modalService.openModal('confirm', 'ยืนยันการชำระเงิน', 'ชำระค่าสินค้าหายเรียบร้อยแล้ว?').then(async (result: any) => {
      console.log(result)
      if (result) {
        this.inventoryService.confirmInventoryComplete(this.inventoryId).then((response: any) => {
          console.log(response)
          if (response.success) {
            this.inventory = response.data;
            this.modalService.openModal('success', 'ยืนยันการชำระเงินสำเร็จ', '')
          } else {
            this.modalService.openModal('fail', 'ยืนยันการชำระเงินไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    });
  }

  canAccessConfirmPayment() {
    return this.authGaurdService.canAction(['manager', 'admin'])
  }

  canAccessDownload() {
    return this.authGaurdService.canAction(['manager', 'admin'])
  }

  async getProductTransaction(serialNo: string) {
    return await this.inventoryService.getProductTransaction(serialNo).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data
      } else {
        return {}
      }
    })
  }

  async clickProductTraction(serialNo: string) {
    this.processing = true;
    console.log(serialNo)
    const filter = {
      serial_no: serialNo
    }

    const fetchProductTransaction = await this.getProductTransaction(serialNo);
    const transactions = fetchProductTransaction?.transaction?.map((e: any) => ({ ...e, date: e.date || dayjs().format('YYYY-MM-DD HH:mm') })) || []
    const sortedTransactions = transactions.sort((a: any, b: any) => (this.utilService.dayjs(b.date).valueOf() - this.utilService.dayjs(a.date).valueOf()))
    this.productTransaction = {
      serial_no: serialNo,
      ...fetchProductTransaction,
      transaction: sortedTransactions
    }
    this.showProductTransaction = true;
    this.processing = false;

    console.log(this.productTransaction)
  }

  callBackProductTransaction = (data: any) => {
    if (data.confirm) {
      this.confirmReceiptBySerialNo(data);
    } else {
      this.showProductTransaction = false;
    }
  }

  confirmReceiptBySerialNo(data: any) {
    console.log(data);
    if (!data.detail.sku) {
      this.modalService.openModal('info', 'ไม่พบบาร์โค้ดในระบบ', 'ไม่สามารถรับเข้าได้ เนื่องจากบาร์โค้ดไม่ถูกต้อง')
      return;
    }
    if (!data.detail.transaction || data.detail.transaction.length === 0) {
      this.modalService.openModal('info', 'ไม่สามารถตรวจสอบได้', 'ไม่สามารถรับเข้าได้ เนื่องจากไม่พบประวัติการทำรายการบาร์โค้ดนี้')
      return;
    }

    const latestTransaction = data.detail.transaction[0]
    if (latestTransaction?.shopId !== this.shopId) {
      this.modalService.openModal('info', 'ร้านค้าไม่ถูกต้อง', 'ไม่สามารถสร้างใบรับเข้าได้ เนื่องจากไม่ใช่จากร้านค้าล่าสุดที่ทำรายการ')
      return;
    }

    this.modalService.openModal('confirm', 'ยืนยันการรับเข้าสินค้า', 'ระบบจะทำการสร้างใบรับเข้า และนำบาร์โค้ดออกจากรายการสินค้าใหม่?').then(async (result: any) => {
      if (result) {
        this.isLoaded = false
        const body = { serial_no: data.detail.serial_no, shop_id: this.shopId }
        this.inventoryService.updateInventoryCountSerial(this.inventoryId, body).then((response: any) => {
          console.log(response)
          this.isLoaded = true;

          if (response.success) {
            this.inventory = response.data;
            this.new = this.new.filter((e: any) => e.serial_no !== data.detail.serial_no)
            this.modalService.openModal('success', 'สร้างใบรับเข้าสำเร็จ', '')
            this.showProductTransaction = false;

          } else {
            let message = 'ไม่สามารถสร้างใบรับเข้าได้';
            if (response.message === 'can not get cost') {
              message = 'เกิดข้อผิดพลาดในการโหลดต้นทุน กรุณาตรวจสอบบาร์และโค้ดลองใหม่อีกครั้ง'
            } else if (response.message === 'not found cost') {
              message = 'ไม่พบต้นทุนของสินค้านี้ กรุณาตรวจสอบบาร์โค้ดลองและใหม่อีกครั้ง'
            } else if (response.message === 'shop id not match') {
              message = 'ไม่สามารถสร้างใบรับเข้าได้ เนื่องจากพบบาร์โค้ดนี้พร้อมขายในร้านอื่น กรุณาตรวจสอบสินค้าที่ร้านค้าต้นทางหรือติดต่อเจ้าหน้าที่'
            } else if (response.message === 'can not get shop') {
              message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลร้านค้า กรุณาตรวจสอบบาร์โค้ดและลองใหม่อีกครั้ง'
            } else if (response.message === 'get receipt failed') {
              message = 'เกิดข้อผิดพลาดในการโหลดใบรับเข้า กรุณาลองใหม่อีกครั้ง'
            } else if (response.message === 'receipt already existed') {
              message = 'หมายเลขใบรับเข้าซ้ำ กรุณาลองใหม่อีกครั้ง'
            }
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
          }

        })
      }
    });
  }

  downloadLossSerial() {
    this.inventoryService.getInventoryReport(this.inventoryId).then(res => {
      if (res.success && res.data) {
        this.inventoryService.generateInventoryReport(res.data, 'รายการสินค้าหาย')
      } else {
        this.modalService.openModal('info', 'เกิดข้อผิดพลาด', 'ไม่สามารถโหลดเอกสารได้')
      }
    })
  }

}
