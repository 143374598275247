<div class="space-y-4 py-4" [ngClass]="{'opacity-70': isLoading}">
    <div class="relative w-full flex items-center justify-end gap-2 px-4 h-10 text-gray-500 ">
        <!-- <mat-form-field class="w-60">
            <button class="flex justify-between px-4 w-full py-2 gap-2" (click)="picker.open()">
                <div><span class="pr-2">ข้อมูล ณ วันที่</span><span>{{onDateSelected(selectedDate)}}</span></div>
                <fa-icon [icon]="faCalendarDays" class="text-gray-400"></fa-icon>
            </button>
            <input id="datepicker" matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" [max]="maxDate"
                (dateChange)="getDataReport(selectedDate)" hidden readonly>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->

        <mat-form-field class="w-[340px]">
            <button class="flex justify-between px-4 w-full py-2 gap-2" (click)="picker.open()">
                <div class="text-nowrap">
                    <span class="pr-2">ข้อมูล ณ วันที่</span>
                    <span class="whitespace-nowrap">{{onDateSelected(selectedStartDate)}} ถึง
                        {{onDateSelected(selectedEndDate)}}</span>
                </div>
                <fa-icon [icon]="faCalendarDays" class="text-gray-400"></fa-icon>
            </button>
            <mat-date-range-input class="!hidden" id="datepicker" [rangePicker]="picker" [max]="maxDate">
                <input matStartDate [(ngModel)]="selectedStartDate" [max]="maxDate">
                <input matEndDate [(ngModel)]="selectedEndDate" [max]="maxDate" (dateChange)="onDateRangeSelected()">
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

    </div>

    <div class="px-4">
        <div class="rounded-lg bg-gray-600 text-white shadow-md">
            <div>
                <div class="border-b-2 border-gray-100 p-2">
                    <div class="flex space-x-2">
                        <fa-icon [icon]="faArrowUpRightDots"></fa-icon>
                        <div class="font-bold">
                            การขาย
                        </div>
                    </div>
                    <div class="flex h-24 items-center justify-center">
                        <div class="text-center">
                            <div class="text-2xl md:text-4xl font-bold tracking-wide">
                                {{ salesReport?.sales || 0 | number: '1.0-2' }}
                            </div>
                            <div class="">
                                ยอดขาย
                            </div>
                            <div class="mt-2 flex gap-2">
                                <div class="text-xs font-semibold bg-white text-black rounded-md py-0.5 px-2">ยอดคืน
                                    {{(refundReport?.refund || 0) | number: '1.0-2'}}</div>
                                <div class="text-xs font-semibold bg-white text-black rounded-md py-0.5 px-2">ยอดสุทธิ
                                    {{((salesReport?.sales || 0)-(refundReport?.refund || 0)) | number: '1.0-2'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2">
                    <div class="p-2 border-r-2 border-gray-100">
                        <div class="flex justify-center items-center text-center h-20">
                            <div>
                                <div class="text-2xl md:text-4xl font-bold tracking-wider">
                                    {{ salesReport?.orders || 0 | number }}
                                </div>
                                <div>
                                    คำสั่งซื้อ
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="p-2">
                        <div class="flex justify-center items-center text-center h-20">
                            <div>
                                <div class="text-2xl md:text-4xl font-bold tracking-wider">
                                    {{ salesReport?.sales/salesReport?.orders || 0 | number: '1.0-2' }}
                                </div>
                                <div>
                                    มูลค่าเฉลี่ย
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="px-4">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div class="">
                <div class="border rounded-lg overflow-hidden shadow-sm" [ngClass]="{'opacity-60': !newCustomerReport}">

                    <div class="relative flex p-4 bg-blue-500">
                        <div class="bg-white h-20 w-16 flex items-center justify-center rounded-full">
                            <fa-icon [icon]="faUsers" class="text-3xl"></fa-icon>
                        </div>
                        <div class="pl-4 flex items-center text-white">
                            <div>
                                <div class="flex items-center text-xl font-bold space-x-1">
                                    <fa-icon [icon]="faPlus" class="text-sm"></fa-icon>
                                    @if(newCustomerReport) {
                                    <div>{{ (newCustomerReport?.new_1_day?.length || 0) | number }}</div>
                                    }@else {
                                    <span class="loader-report !w-5 !h-5 !border-4"></span>
                                    }
                                    <div class="pl-2">คน</div>
                                </div>
                                <div class="pt-2">
                                    สมาชิกใหม่วันนี้
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex items-end justify-center space-x-2 w-full text-center bg-white py-2 text-sm 2xl:text-base">
                        <div>
                            สมาชิก CRM สะสม
                        </div>
                        @if(newCustomerReport) {
                        <div class="text-blue-500 font-bold text-base 2xl:text-xl">{{newCustomerReport?.all?.length |
                            number}}</div>
                        }@else {
                        <div class="loader-report !w-5 !h-5 !border-4"></div>
                        }

                    </div>
                </div>

                <div class="grid grid-cols-2 gap-4 pt-4">
                    <div class="relative border bg-white rounded-lg overflow-hidden"
                        [ngClass]="{'opacity-60': !discountReport}">
                        <div class="flex space-x-2 px-4 pt-4">
                            <fa-icon [icon]="faHandHoldingDollar"></fa-icon>
                            <div class="font-bold">
                                ส่วนลด
                            </div>
                        </div>
                        @if (discountReport) {
                        <div class="flex w-full justify-center py-6">
                            <div class="relative">
                                <div class="absolute flex w-full h-full justify-center items-center z-10 text-center">
                                    <div>
                                        <div class="">
                                            {{ discountReport?.discount || 0 | number}}
                                        </div>
                                        <div class="">บาท</div>
                                    </div>
                                </div>
                                <app-circle-progress color="#facc15"
                                    [value]="calculateDiscountPercent(discountReport.discount,discountReport.sales) || 0">
                                </app-circle-progress>
                            </div>
                        </div>
                        <div class="text-center">
                            {{ calculateDiscountPercent(discountReport.discount,discountReport.sales) | number }}%
                            ของยอดขาย
                        </div>
                        }@else {
                        <div class="w-full h-44 z-50 flex justify-center items-center relative">
                            <span class="loader-report"></span>
                        </div>
                        <div class="flex justify-center h-6 w-full px-4">
                            <div class="animate-pulse flex space-x-4 w-3/4">
                                <div class="grid grid-cols-11 gap-2 w-full">
                                    <div class="h-3 bg-gray-300 rounded col-span-2"></div>
                                    <div class="h-3 bg-gray-300 rounded col-span-9"></div>
                                </div>
                            </div>
                        </div>
                        }
                        <button class="w-full text-sm 2xl:text-base bg-gray-600 text-white py-1 mt-4">
                            <span class="opacity-0">ดูเพิ่ม</span>
                        </button>
                    </div>


                    <div class="border bg-white rounded-lg overflow-hidden"
                        [ngClass]="{'opacity-60': !orderStatusReport}">
                        <div class="flex space-x-2 px-4 pt-4">
                            <fa-icon [icon]="faSackXmark"></fa-icon>
                            <div class="font-bold">
                                ยกเลิก
                            </div>
                        </div>
                        @if (orderStatusReport) {
                        <div class="flex w-full justify-center py-6">

                            <div class="relative">
                                <div class="absolute flex w-full h-full justify-center items-center z-10 text-center">
                                    <div>
                                        <div class="">
                                            {{ cancelOrderStatus(orderStatusReport) | number}}
                                        </div>
                                        <div class="">คำสั่งซื้อ</div>
                                    </div>
                                </div>
                                <app-circle-progress color="#ef4444"
                                    [value]="calculateCancelOrderStatusPercent(orderStatusReport) || 0">
                                </app-circle-progress>
                            </div>

                        </div>
                        <div class="text-center">
                            {{ calculateCancelOrderStatusPercent(orderStatusReport) | number }}% ของคำสั่งซื้อ
                        </div>
                        }@else {
                        <div class="w-full h-44 z-50 flex justify-center items-center relative">
                            <span class="loader-report"></span>
                        </div>
                        <div class="flex justify-center h-6 w-full px-4">
                            <div class="animate-pulse flex space-x-4 w-3/4">
                                <div class="grid grid-cols-11 gap-2 w-full">
                                    <div class="h-3 bg-gray-300 rounded col-span-2"></div>
                                    <div class="h-3 bg-gray-300 rounded col-span-9"></div>
                                </div>
                            </div>
                        </div>
                        }
                        <button class="w-full text-sm 2xl:text-base bg-gray-600 text-white py-1 mt-4">
                            <span class="opacity-0">ดูเพิ่ม</span>
                        </button>
                    </div>
                </div>



            </div>
            <div class="border p-4 bg-white rounded-lg shadow-sm" [ngClass]="{'opacity-60': !bestSellerProductReport}">

                <div class="font-bold">
                    สินค้าขายดี
                </div>
                <div class="pt-4 w-full overflow-x-auto">
                    <div class="div-table overflow-x-auto">
                        <table>
                            <thead>
                                <tr class="thead-tr">
                                    <th class="text-center hidden md:table-cell">
                                        #
                                    </th>
                                    <th class="text-left">
                                        สินค้า
                                    </th>
                                    <th class="text-right">
                                        ขายวันนี้
                                    </th>
                                </tr>
                            </thead>
                            @if(bestSellerProductReport && bestSellerProductReport.length > 0){
                            <tbody>
                                @for (item of bestSellerProductReport; track $index) {
                                <tr class="tbody-tr">
                                    <td class="w-14 text-center hidden md:table-cell">
                                        @if ($index > 2) {
                                        {{ $index + 1 }}
                                        } @else if($index > 0) {
                                        <fa-icon [icon]="faMedal"
                                            [ngClass]="{'text-gray-400': $index === 1, 'text-[#CD7F32]': $index === 2}"></fa-icon>
                                        } @else {
                                        <fa-icon [icon]="faTrophy" class="text-yellow-400"></fa-icon>
                                        }
                                    </td>
                                    <td class="min-w-60">
                                        <div class="flex items-center">
                                            <div>
                                                <img class="w-10 h-10 rounded-md"
                                                    src="https://storage.remaxthailand.co.th/img/product/{{item.sku}}/1.jpg"
                                                    alt="item"
                                                    onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                            </div>
                                            <div class="pl-2">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right min-w-24">
                                        {{ item.quantity }}
                                    </td>
                                </tr>
                                }
                            </tbody>
                            }@else if(bestSellerProductReport && bestSellerProductReport.length === 0){
                            <tbody>
                                <tr class="tbody-tr">
                                    <td colspan="100" class="text-center py-10">
                                        <div class="flex flex-col justify-center items-center w-full">
                                            <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                            <div class="text-center pt-4">ไม่พบสินค้า</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            }@else {
                            <tbody>
                                @for (item of loopAnimatePulse(5); track $index) {
                                <tr class="tbody-tr">
                                    <td class="w-14 text-center hidden md:table-cell h-[61px]">
                                        <div class="animate-pulse flex space-x-4 w-full">
                                            <div class="flex-1 space-y-2 py-1 w-full">
                                                <div class="h-3 bg-gray-300 rounded"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="min-w-60">
                                        <div class="animate-pulse flex space-x-4 w-full">
                                            <div class="flex-1 space-y-2 py-1 w-full">
                                                <div class="h-3 bg-gray-300 rounded"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right min-w-24">
                                        <div class="animate-pulse flex space-x-4 w-full">
                                            <div class="flex-1 space-y-2 py-1 w-full">
                                                <div class="h-3 bg-gray-300 rounded"></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                }
                            </tbody>
                            }
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="px-4">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
            <div class="border rounded-lg bg-white p-4 shadow-sm"
                [ngClass]="{'!bg-gray-50 !opacity-60': !paymentMethodQtyChart}">

                <div class="flex space-x-2">
                    <fa-icon [icon]="faWallet"></fa-icon>
                    <div class="font-bold">
                        ช่องทางการชำระ (จำนวน)
                    </div>
                </div>

                <div class="w-full flex justify-center py-6">
                    <div class="w-2/3 max-w-80 aspect-square my-6">
                        @if (paymentMethodQtyChart) {
                        @if(paymentMethodQtyChartShow) {
                        <app-charts [chartId]="paymentMethodQtyChart.id" [chartType]="paymentMethodQtyChart.type"
                            [chartData]="paymentMethodQtyChart.data" [chartOptions]="paymentMethodQtyChart.options">
                        </app-charts>
                        }@else {
                        <div class="w-full">
                            <div class="w-full aspect-square rounded-full bg-gray-100 p-20">
                                <div class="w-full aspect-square rounded-full bg-white">
                                </div>
                            </div>
                        </div>
                        }
                        }@else{
                        <div class="w-full h-full z-50 flex justify-center items-center relative">
                            <span class="loader-report"></span>
                        </div>
                        }
                    </div>
                </div>
                <div class="w-full flex justify-center pb-2 min-h-44">
                    <div class="w-2/3 space-y-2">
                        @if (paymentMethodQtyChart) {
                        @for (label of paymentMethodQtyChart.data.labels; track $index) {
                        <div class="flex space-x-2">
                            <div class="pt-0.5">
                                <div class="h-4 rounded-md w-4" [ngStyle]="{'background-color': bgTemplate[$index]}">
                                </div>
                            </div>
                            <div>
                                <div class="text-sm 2xl:text-base text-gray-600">
                                    {{ label }}
                                </div>
                                <div class="text-xs 2xl:text-sm">
                                    <span class="text-orange-600">{{ paymentMethodQtyChart.data.datasets[0].data[$index]
                                        |
                                        number }}</span>
                                    <span class="pl-1">คำสั่งซื้อ</span>
                                </div>
                            </div>
                        </div>
                        }
                        }@else {
                        <div class="animate-pulse flex space-x-4">
                            <div class="flex-1 space-y-2 py-1">
                                @for (item of loopAnimatePulse(4); track $index) {
                                <div class="grid grid-cols-11 gap-2">
                                    <div class="h-3 bg-gray-300 rounded col-span-2"></div>
                                    <div class="h-3 bg-gray-300 rounded col-span-9"></div>
                                </div>
                                <div class="h-3 bg-gray-300 w-1/3 rounded"></div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div class="border rounded-lg bg-white p-4 shadow-sm"
                [ngClass]="{'!bg-gray-50 !opacity-60': !paymentMethodValueChart}">

                <div class="flex space-x-2">
                    <fa-icon [icon]="faMoneyCheckDollar"></fa-icon>
                    <div class="font-bold">
                        ช่องทางการชำระ (มูลค่า)
                    </div>
                </div>

                <div class="w-full flex justify-center py-6">
                    <div class="w-2/3 max-w-80 aspect-square my-6">
                        @if (paymentMethodValueChart) {
                        @if(paymentMethodValueChartShow) {
                        <app-charts [chartId]="paymentMethodValueChart.id" [chartType]="paymentMethodValueChart.type"
                            [chartData]="paymentMethodValueChart.data" [chartOptions]="paymentMethodValueChart.options">
                        </app-charts>
                        }@else {
                        <div class="w-full">
                            <div class="w-full aspect-square rounded-full bg-gray-100 p-20">
                                <div class="w-full aspect-square rounded-full bg-white">
                                </div>
                            </div>
                        </div>
                        }
                        }@else {
                        <div class="w-full h-full z-50 flex justify-center items-center relative">
                            <span class="loader-report"></span>
                        </div>
                        }
                    </div>
                </div>
                <div class="w-full flex justify-center pb-2">
                    <div class="w-2/3 space-y-2">
                        @if (paymentMethodValueChart) {
                        @for (label of paymentMethodValueChart.data.labels; track $index) {
                        <div class="flex space-x-2">
                            <div class="pt-0.5">
                                <div class="h-4 rounded-md w-4" [ngStyle]="{'background-color': bgTemplate[$index]}">
                                </div>
                            </div>
                            <div>
                                <div class="text-sm 2xl:text-base text-gray-600">
                                    {{ label }}
                                </div>
                                <div class="text-xs 2xl:text-sm">
                                    <span class="text-orange-600">{{
                                        paymentMethodValueChart.data.datasets[0].data[$index] |
                                        number }}</span>
                                    <span class="pl-1">บาท</span>
                                </div>
                            </div>
                        </div>
                        }
                        }@else {
                        <div class="animate-pulse flex space-x-4">
                            <div class="flex-1 space-y-2 py-1">
                                @for (item of loopAnimatePulse(4); track $index) {
                                <div class="grid grid-cols-11 gap-2">
                                    <div class="h-3 bg-gray-300 rounded col-span-2"></div>
                                    <div class="h-3 bg-gray-300 rounded col-span-9"></div>
                                </div>
                                <div class="h-3 bg-gray-300 w-1/3 rounded"></div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="px-4">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">


            <!-- <div class="border p-4 bg-white rounded-lg shadow-sm">

                <div class="font-bold">
                    กราฟช่วงเวลา/วันที่ขาย
                </div>
                <div class="flex w-full h-full  max-h-96 lg:max-h-[480px]">
                    <div class="py-6 w-11/12">
                        <app-charts [chartId]="exampleSaleChart.id" [chartType]="exampleSaleChart.type"
                            [chartData]="exampleSaleChart.data" [chartOptions]="exampleSaleChart.options">
                        </app-charts>
                    </div>
                </div>

            </div>

            <div class="border p-4 bg-white rounded-lg shadow-sm">

                <div class="font-bold">
                    ข้อมูลสต็อก
                </div>

                <div class="lg:flex lg:justify-center h-full">

                    <div class="w-full lg:w-2/3 flex justify-center">
                        <div class="w-3/5 max-w-80 aspect-square my-6">
                            <app-charts [chartId]="categoryStockChart.id" [chartType]="categoryStockChart.type"
                                [chartData]="categoryStockChart.data" [chartOptions]="categoryStockChart.options">
                            </app-charts>
                        </div>
                    </div>

                    <div class="flex justify-center lg:justify-start items-center">

                        <div class="space-y-2 w-2/3 lg:w-full text-left">
                            @for (category of selectedCategories; track $index) {
                            <div class="flex space-x-2">
                                <div class="pt-0.5">
                                    <div class="h-4 rounded-md w-4"
                                        [ngStyle]="{'background-color': categoryColors[$index]}">
                                    </div>
                                </div>
                                <div>
                                    <div class="text-sm 2xl:text-base">
                                        {{ category.name }}
                                    </div>
                                    <div class="space-x-1 text-xs 2xl:text-sm">
                                        <span>มูลค่า <span class="text-orange-600">{{ category.value| number
                                                }}</span></span>
                                        <span>({{ category.qty | number }} ชิ้น)</span>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                    </div>

                </div>




            </div> -->

            <ng-container *ngTemplateOutlet="reportOrderContent"> </ng-container>
            <ng-container *ngTemplateOutlet="reportReturnOrderContent"> </ng-container>

        </div>
    </div>


</div>


<ng-template #reportOrderContent>
    <div class="p-4 border bg-white rounded-lg lg:col-span-2 shadow-sm" [ngClass]="{'opacity-60': !orderReport}">
        <div class="flex justify-between">
            <div class="font-bold">
                รายการคำสั่งซื้อ
            </div>
            @if (canAccessExportExcel()) {
            <div>
                <button class="flex space-x-2 items-center underline underline-offset-2 text-amber-500"
                    (click)="downloadReport()">
                    <div>
                        <fa-icon [icon]="faCloudArrowDown" class="text-xl"></fa-icon>
                    </div>
                    <div>
                        ดาวน์โหลด
                    </div>
                </button>
            </div>
            }
        </div>

        <div class="pt-4 w-full overflow-x-auto">
            <div class="div-table overflow-x-auto">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                วันที่
                            </th>
                            <th class="text-left hidden lg:table-cell">
                                เลขที่
                            </th>
                            <th class="text-left hidden md:table-cell">
                                วิธีชำระ
                            </th>
                            <th class="text-right hidden lg:table-cell">
                                จำนวนสินค้า
                            </th>
                            <th class="text-right hidden lg:table-cell">
                                ส่วนลด
                            </th>
                            <th class="text-right">
                                ยอดสุทธิ
                            </th>
                            <th class="text-center hidden lg:table-cell">
                                สถานะ
                            </th>
                            <th class="text-right hidden lg:table-cell">
                                พนักงาน
                            </th>
                            <th class="text-right"></th>
                        </tr>
                    </thead>
                    @if(orderReport && orderReport.data.length > 0){
                    <tbody>
                        @for (order of orderReport.data; track $index) {
                        <tr class="tbody-tr">
                            <td class="w-44">
                                <div class="whitespace-nowrap">
                                    {{ utilService.dayjs(order.created.date).format('D MMM YY') }}
                                </div>
                                <div class="text-xs text-gray-500 whitespace-nowrap">
                                    {{ utilService.dayjs(order.created.date).format('HH:mm น.') }}
                                </div>
                            </td>
                            <td class="w-44 hidden lg:table-cell">
                                {{ order.order_no }}
                            </td>
                            <td class="lg:w-44 hidden md:table-cell">
                                {{ order.payment_method? order.payment_method.name: '-' }}
                                <button [disabled]="(order?.payment?.note || order?.payment?.image) ? true: false"
                                    class="text-gray-300 duration-300"
                                    [ngClass]="{'text-gray-500 hover:text-blue-500 active:text-blue-500': order?.payment?.note || order?.payment?.image}">
                                    <fa-icon [icon]="faCircleInfo"
                                        (click)="selecetedPaymentInfo(order?.payment, order?.payment_method)"></fa-icon>
                                </button>
                            </td>
                            <td class="w-36 text-right hidden lg:table-cell">
                                {{ order.items.length | number }}
                            </td>
                            <td class="w-36 text-right hidden lg:table-cell">
                                {{ order.total_discount | number : '1.2-2' }}
                                <button [disabled]="order.discounts.length === 0? true: false"
                                    class="text-gray-300 duration-300"
                                    [ngClass]="{'text-gray-500 hover:text-blue-500 active:text-blue-500': order.discounts.length > 0}">
                                    <fa-icon [icon]="faCircleInfo"
                                        (click)="selecetedCouponInfo(order.discounts, order.total_discount)"></fa-icon>
                                </button>
                            </td>
                            <td class="md:w-36 text-right">
                                {{ order.total_price | number : '1.2-2' }}
                            </td>
                            <td class="md:w-36 text-center hidden lg:table-cell">
                                <div [ngClass]="displayStatusColor(order.status)">
                                    {{ displayStatusOrder(order.status) }}
                                </div>
                            </td>
                            <td class="w-36 text-right hidden lg:table-cell">
                                {{ getUserDetail(order.created.by)?.name }}
                            </td>
                            <td class="w-36 text-right">
                                <button
                                    class="py-2 hover:text-blue-500 duration-300 rounded-lg underline underline-offset-2 whitespace-nowrap"
                                    routerLink="/sales/sale-detail"
                                    [queryParams]="{no: order.order_no}">ดูเพิ่ม</button>
                            </td>
                        </tr>
                        }
                    </tbody>
                    }@else if (orderReport && orderReport.data.length === 0) {
                    <tbody>
                        <tr class="tbody-tr">
                            <td colspan="100" class="text-center py-10">
                                <div class="flex flex-col justify-center items-center w-full">
                                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                    <div class="text-center pt-4">ไม่พบคำสั่งซื้อ</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }@else {
                    <!-- start loader -->
                    <ng-container *ngTemplateOutlet="tableLoader"> </ng-container>
                    <!-- end loader -->
                    }
                </table>
            </div>

        </div>

        <div class="flex w-full justify-center">
            @if (orderReport && orderReport.count > orderReport.data.length) {
            <button
                class="w-fit text-sm 2xl:text-base bg-gray-600 text-white py-1 px-4 rounded-full content-center space-x-2"
                (click)="seeAllOrder()">
                <span>ดูทั้งหมด</span>
                <span>{{orderReport?.count || 0 |
                    number}}</span>
                <span>รายการ</span>
                <span><fa-icon [icon]="faArrowDownLong" class="text-xs 2xl:text-sm"></fa-icon></span>
            </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #reportReturnOrderContent>
    <div class="p-4 border bg-white rounded-lg lg:col-span-2 shadow-sm" [ngClass]="{'opacity-60': !returnReport}">
        <div class="flex justify-between">
            <div class="font-bold">
                รายการรับคืน
            </div>
            @if (canAccessExportExcel()) {
            <div>
                <button class="flex space-x-2 items-center underline underline-offset-2 text-amber-500"
                    (click)="downloadReturnReport()">
                    <div>
                        <fa-icon [icon]="faCloudArrowDown" class="text-xl"></fa-icon>
                    </div>
                    <div>
                        ดาวน์โหลด
                    </div>
                </button>
            </div>
            }
        </div>

        <div class="pt-4 w-full overflow-x-auto">
            <div class="div-table overflow-x-auto">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                วันที่
                            </th>
                            <th class="text-left hidden lg:table-cell">
                                เลขที่
                            </th>
                            <th class="text-left hidden md:table-cell">
                                วิธีคืน
                            </th>
                            <th class="text-right hidden lg:table-cell">
                                จำนวนสินค้า
                            </th>
                            <th class="text-right hidden lg:table-cell">
                                ส่วนลด
                            </th>
                            <th class="text-right">
                                ยอดสุทธิ
                            </th>
                            <th class="text-center hidden lg:table-cell">
                                สถานะ
                            </th>
                            <th class="text-right hidden lg:table-cell">
                                พนักงาน
                            </th>
                            <th class="text-right"></th>
                        </tr>
                    </thead>
                    @if(returnReport && returnReport.data.length > 0){
                    <tbody>
                        @for (order of returnReport.data; track $index) {
                        <tr class="tbody-tr">
                            <td class="w-44">
                                <div class="whitespace-nowrap">
                                    {{ utilService.dayjs(order.return_date).format('D MMM YY') }}
                                </div>
                                <div class="text-xs text-gray-500 whitespace-nowrap">
                                    {{ utilService.dayjs(order.return_date).format('HH:mm น.') }}
                                </div>
                            </td>
                            <td class="w-44 hidden lg:table-cell">
                                {{ order.return_no }}
                            </td>
                            <td class="lg:w-44 hidden md:table-cell">
                                {{ displayPayment(order.refund_detail.payment_method) || '-' }}
                                <button [disabled]="(order?.refund_detail?.note) ? true: false"
                                    class="text-gray-300 duration-300"
                                    [ngClass]="{'text-gray-500 hover:text-blue-500 active:text-blue-500': order?.refund_detail?.note}">
                                    <fa-icon [icon]="faCircleInfo"
                                        (click)="selecetedPaymentInfo(order?.refund_detail, order?.refund_detail?.payment_method)"></fa-icon>
                                </button>
                            </td>
                            <td class="w-36 text-right hidden lg:table-cell">
                                {{ order.items.length | number }}
                            </td>
                            <td class="w-36 text-right hidden lg:table-cell">
                                {{ order.total_discount | number : '1.2-2' }}
                            </td>
                            <td class="md:w-36 text-right">
                                {{ order.total_price | number : '1.2-2' }}
                            </td>
                            <td class="md:w-36 text-center hidden lg:table-cell">
                                <div [ngClass]="displayStatusReturnColor(order.status)">
                                    {{ displayStatusReturn(order.status) }}
                                </div>
                            </td>
                            <td class="w-36 text-right hidden lg:table-cell">
                                {{ getUserDetail(order.created.by)?.name }}
                            </td>
                            <td class="w-36 text-right">
                                <button
                                    class="py-2 hover:text-blue-500 duration-300 rounded-lg underline underline-offset-2 whitespace-nowrap"
                                    routerLink="/exchange/exchange-detail"
                                    [queryParams]="{return_no: order.return_no}">ดูเพิ่ม</button>
                            </td>
                        </tr>
                        }
                    </tbody>
                    }@else if (returnReport && returnReport.data.length === 0) {
                    <tbody>
                        <tr class="tbody-tr">
                            <td colspan="100" class="text-center py-10">
                                <div class="flex flex-col justify-center items-center w-full">
                                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                    <div class="text-center pt-4">ไม่พบคำสั่งซื้อ</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    }@else {
                    <!-- start loader -->
                    <ng-container *ngTemplateOutlet="tableLoader"> </ng-container>
                    <!-- end loader -->
                    }
                </table>
            </div>

        </div>

        <div class="flex w-full justify-center">
            @if (returnReport && returnReport.count > returnReport.data.length) {
            <button
                class="w-fit text-sm 2xl:text-base bg-gray-600 text-white py-1 px-4 rounded-full content-center space-x-2"
                (click)="seeAllReturn()">
                <span>ดูทั้งหมด</span>
                <span>{{returnReport?.count || 0 |
                    number}}</span>
                <span>รายการ</span>
                <span><fa-icon [icon]="faArrowDownLong" class="text-xs 2xl:text-sm"></fa-icon></span>
            </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #tableLoader>
    <tbody>
        <tr class="tbody-tr">
            <td class="w-44">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="w-44 hidden lg:table-cell">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="lg:w-44 hidden md:table-cell">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="w-36 text-right hidden lg:table-cell">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="w-36 text-right hidden lg:table-cell">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="md:w-36 text-right">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="md:w-36 text-center hidden lg:table-cell">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="w-36 text-right hidden lg:table-cell">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
            <td class="w-36 text-right">
                <div class="animate-pulse flex space-x-4 w-full">
                    <div class="flex-1 space-y-2 py-1 w-full">
                        <div class="h-3 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</ng-template>