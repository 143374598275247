<div class="p-4">
    <div class="pb-4">
        <button class="btn-secondary w-full" routerLink="/claim/claim-create">
            เช็คประกันและส่งเคลมสินค้า
        </button>
    </div>
    <div class="pt-4 border-t space-y-1">
        <div class="font-bold">
            รายการส่งเคลม
        </div>

        <div class="pb-2">
            <div class="rounded-lg bg-white grid grid-cols-2 divide-x">
                <button class="text-center py-1.5 rounded-l-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state === 'ongoing'}"
                    (click)="changeState('ongoing')">
                    รอดำเนินการ ({{countClaim['ongoing']}})
                </button>
                <button class="text-center py-1.5 rounded-r-lg text-gray-600"
                    [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state !== 'ongoing'}"
                    (click)="changeState('success')">
                    สำเร็จ ({{countClaim['success']}})
                </button>
            </div>
        </div>

        @if (isLoaded) {
        @if(!items || items.length === 0){
        <div class="text-center py-10">
            <div class="flex flex-col justify-center items-center w-full">
                <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                <div class="text-center pt-4">ไม่พบรายการเคลม</div>
            </div>
        </div>
        }

        @for (item of items; track $index) {

        <div class="grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg relative cursor-pointer"
            (click)="clickShowDetail(item.claim_id)">
            <div class="col-span-1">
                <div class="w-full aspect-square">
                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + item.product.sku + '/1.jpg'"
                        [alt]="item.sku" class="rounded-sm"
                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                </div>
            </div>
            <div class="flex flex-col justify-between w-full col-span-4 md:col-span-7 xl:col-span-11">
                <div class="text-sm md:text-base line-clamp-2 h-10">
                    {{ item.product.name }}
                </div>
                <div class="absolute bottom-2 right-2 xl:top-2 bg-yellow-200 rounded-md px-2 py-1 text-xs md:text-sm w-fit h-fit"
                    [ngClass]="item.response? colorStatus(item.response.claimStatus, item.is_receipt) : 'bg-yellow-200'">
                    {{ item.response? displayStatus(item.response.claimStatus, item.is_receipt) : 'ส่งเรื่องแล้ว'}}
                </div>
                <div class="text-sm text-gray-400">
                    {{dateFormat(item.created.date)}}
                </div>
            </div>
        </div>
        }

        @if(loadingMore) {
        <div class="flex justify-center pt-10">
            <app-loader></app-loader>
        </div>
        }
        @if(countClaim[state] > 10 && countClaim[state] > items.length){
        <div class="flex items-center justify-center w-full pt-4 space-x-4">
            <button (click)="loadMore()"
                class="btn-light !py-1 w-full lg:w-fit flex justify-between md:justify-center md:space-x-6 xl:space-x-8 items-center">
                <div class="flex space-x-1 justify-start items-center">
                    <div>แสดง</div>
                    <div class="text-primary-500 font-medium">{{items.length}}</div>
                    <div>/</div>
                    <div>{{countClaim[state]}}</div>
                </div>
                <div class="flex space-x-2 justify-end items-center font-medium">
                    <div>แสดงเพิ่มเติม</div>
                    <div><fa-icon [icon]="faArrowDown"></fa-icon></div>
                </div>
            </button>
        </div>
        }

        } @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }

    </div>
</div>

<app-claim-detail-dialog [open]="showDetail" [detail]="claimDetail"
    (callBackClaimDetail)="callBackClaimDetail($event)"></app-claim-detail-dialog>