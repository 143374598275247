import { Injectable } from '@angular/core';
import { InventoryApiService } from '../api/inventory-api.service';
import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private inventoryApiService: InventoryApiService
  ) { }

  getData(shopId: string) {
    return this.inventoryApiService.getData(shopId)
  }

  getProductBySerialNo(body: any) {
    return this.inventoryApiService.getProductBySerialNo(body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventory(shopId: string) {
    return this.inventoryApiService.getInventory(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  createInventory(shopId: string, body: any) {
    return this.inventoryApiService.createInventory(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  inventoryCounting(shopId: string, body: any) {
    return this.inventoryApiService.inventoryCounting(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getProductTransaction(serialNo: string) {
    return this.inventoryApiService.getProductTransaction(serialNo).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  deleteInventory(shopId: string) {
    return this.inventoryApiService.deleteInventory(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }


  getActiveSerialByShop(shopId: string) {
    return this.inventoryApiService.getActiveSerialByShop(shopId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getProductShopByshop(shopId: string, filter: any) {
    return this.inventoryApiService.getProductShopByshop(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  confirmInventory(body: any) {
    return this.inventoryApiService.confirmInventory(body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventoryHistory(shopId: string, filter: any) {
    return this.inventoryApiService.getInventoryHistory(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventoryDetail(inventoryId: string) {
    return this.inventoryApiService.getInventoryDetail(inventoryId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  confirmInventoryComplete(inventoryId: string) {
    return this.inventoryApiService.confirmInventoryComplete(inventoryId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  updateInventoryCountSerial(inventoryId: string, body: any) {
    return this.inventoryApiService.updateInventoryCountSerial(inventoryId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getInventoryReport(inventoryId: string) {
    return this.inventoryApiService.getInventoryReport(inventoryId).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  syncStockProduct(shopId: string, body: any) {
    return this.inventoryApiService.syncStockProduct(shopId, body).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  async generateInventoryReport(data: any[], fileName: string) {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('สินค้ารับคืน');
    worksheet.columns = [
      { header: 'SN', key: 'serial_no', width: 20 },
      { header: 'สินค้า', key: 'name', width: 60 },
      { header: 'วันที่ขายล่าสุด', key: 'last_sold', width: 30 },
      { header: 'วันที่รับคืนล่าสุด', key: 'last_return', width: 30 },
      { header: 'วันที่รับเข้า', key: 'receive', width: 30 },
      { header: 'ต้นทุน', key: 'cost', width: 15 },
      { header: 'หมายเลขรับเข้า', key: 'purchase_order_no', width: 20 }
    ];

    data.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.getColumn('A').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber === 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' }
        };
      }
      if (rowNumber > 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF99' },
        };
      }
    });

    worksheet.getColumn('C').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber === 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00B050' }
        };
      }
      if (rowNumber > 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C6EFCE' },
        };
        cell.font = { color: { argb: '4F6228' } }
      }
    });

    worksheet.getColumn('D').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber === 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0070C0' }
        };
      }
      if (rowNumber > 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C5D9F1' },
        };
        cell.font = { color: { argb: '1F497D' } }
      }
    });

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.font = {
          name: 'Cordia New',
          size: 14
        };
      });
    });

    worksheet.getRow(1).font = { name: 'Cordia New', size: 16, bold: true, color: { argb: '000' } };

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, `${fileName}.xlsx`);
  }

  private saveExcelFile(buffer: ArrayBuffer, filename: string) {
    const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, filename);
  }
}
