<div class="p-4 w-full space-y-4 text-sm lg:text-base">

    <div class="hidden lg:flex lg:flex-row gap-4">
        <div class="lg:w-2/6 space-y-4">
            <ng-container *ngTemplateOutlet="imageProductTemplate"> </ng-container>
            <ng-container *ngTemplateOutlet="detailStockProductTemplate"> </ng-container>
        </div>
        <div class="lg:w-4/6 space-y-4">
            <ng-container *ngTemplateOutlet="detailDefaultProductTemplate"> </ng-container>
            <ng-container *ngTemplateOutlet="priceProductTemplate"> </ng-container>
        </div>
    </div>

    <div class="flex lg:hidden flex-col space-y-2">
        <ng-container *ngTemplateOutlet="imageProductTemplate"> </ng-container>
        <ng-container *ngTemplateOutlet="detailDefaultProductTemplate"> </ng-container>
        <ng-container *ngTemplateOutlet="detailStockProductTemplate"> </ng-container>
        <ng-container *ngTemplateOutlet="priceProductTemplate"> </ng-container>
    </div>

</div>

<ng-template #priceProductTemplate>
    <div class="card w-full space-y-2">
        <div class="flex justify-between items-start">
            <div class="pb-4">
                <div class="font-semibold">ราคาสินค้า</div>
                <div class="text-gray-600">ส่วนกำหนดราคาการขายสินค้า</div>
            </div>
            @if (!isEditingPrice) {
            <button class="flex items-center space-x-1 relative px-2 md:px-4 py-1 md:py-2 rounded-lg border bg-white"
                (click)="editPrice()" [disabled]="!canEditPrice()">
                <fa-icon [icon]="faPencil" class="text-base"></fa-icon>
                <span class="pl-1 hidden md:block">แก้ไขราคา</span>
            </button>
            }
        </div>
        <div class="grid grid-cols-1 gap-2 md:gap-6">
            <div class="flex flex-row items-center">
                <label class="w-1/2 md:w-1/3" for="cost-input">ต้นทุนเฉลี่ย</label>
                <div class="w-1/2 md:w-2/3 relative">
                    <input type="number" id="cost-input" class="input !pr-12" [value]="productShopDetail.cost" disabled>
                    <fa-icon [icon]="faBahtSign" class="absolute right-4 -mt-9 text-gray-400"></fa-icon>
                </div>
            </div>
            @if(isEditingPrice) {
            @for(config of priceConfigs; track $index){
            <form [formGroup]="priceForm" class="flex flex-row items-center">
                <label class="w-1/2 md:w-1/3" for="price-input-{{$index}}">{{config.name}}</label>
                <div class="w-1/2 md:w-2/3 relative">
                    <input type="number" id="price-input-{{$index}}" class="input !pr-12"
                        [ngClass]="{ 'input-invalid': priceForm.controls[config.price_config_id].errors }"
                        [formControlName]="config.price_config_id" min="0">
                    <fa-icon [icon]="faBahtSign" class="absolute right-4 -mt-9 text-gray-400"></fa-icon>
                    @if(priceForm.controls[config.price_config_id].errors) {
                    <div class="pt-1 text-sm text-red-500">กรอกตัวเลข ตั้งแต่ 0 ขึ้นไป</div>
                    }
                </div>
            </form>
            }
            }@else {
            @for(config of priceConfigs; track $index){
            <div class="flex flex-row items-center">
                <label class="w-1/2 md:w-1/3" for="price-input-{{$index}}">{{config.name}}</label>
                <div class="w-1/2 md:w-2/3 relative">
                    <input type="number" id="price-input-{{$index}}" class="input !pr-12" disabled
                        [value]="getProductPrice(productShopDetail.prices, config.price_config_id)">
                    <fa-icon [icon]="faBahtSign" class="absolute right-4 -mt-9 text-gray-400"></fa-icon>
                </div>
            </div>
            }
            }

        </div>
        @if (isEditingPrice) {
        <div class="flex justify-end items-center pt-6 w-full">
            <div class="grid grid-cols-2 lg:flex gap-2 w-full lg:w-fit">
                <button class="btn-secondary w-full lg:w-44" (click)="resetEditPrice()">
                    <span class="pl-1">ยกเลิก</span>
                </button>
                <button class="btn-primary w-full lg:w-44" (click)="updatePrice()">
                    <span class="pl-1 whitespace-nowrap">อัพเดตราคาสินค้า</span>
                </button>
            </div>
        </div>
        }
    </div>
</ng-template>

<ng-template #imageProductTemplate>
    <div class="card w-full h-fit flex justify-center">
        <img src="https://storage.remaxthailand.co.th/img/product/{{sku}}/1.jpg" alt=""
            class="w-full md:w-1/2 lg:w-full rounded"
            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
    </div>
</ng-template>

<ng-template #detailStockProductTemplate>
    <div class="card w-full space-y-2">
        <div class="flex justify-between items-start">
            <div class="pb-4">
                <div class="font-semibold">สต๊อกสินค้า</div>
                <div class="text-gray-600">ส่วนกำหนดสต๊อกสินค้า</div>
            </div>
            @if (!isEditingStock) {
            <button class="flex items-center space-x-1 relative px-2 md:px-4 py-1 md:py-2 rounded-lg border bg-white"
                (click)="editStock()">
                <fa-icon [icon]="faPencil" class="text-base"></fa-icon>
                <span class="pl-1 hidden md:block">แก้ไขสต๊อก</span>
            </button>
            }
        </div>
        <div class="flex space-x-2 justify-between">
            <div class="">สถานะ</div>
            <div *ngIf="productShopDetail.summary <= 0 || !productShopDetail.summary"
                class="flex font-semibold items-center text-red-500">
                <div class="bg-red-500 w-2 h-2 rounded-full mr-2"></div>
                <div class="">สินค้าหมด</div>
            </div>
            <div *ngIf="productShopDetail.summary > 0 && productShopDetail.summary < productShopDetail.min_stock"
                class="flex font-semibold items-center text-yellow-500">
                <div class="bg-yellow-500 w-2 h-2 rounded-full mr-2"></div>
                <div class="">สินค้าใกล้หมด</div>
            </div>
            <div *ngIf="productShopDetail.summary > 0 && productShopDetail.summary >= productShopDetail.min_stock"
                class="flex font-semibold items-center text-green-500">
                <div class="bg-green-500 w-2 h-2 rounded-full mr-2"></div>
                <div class="">สินค้าพร้อมขาย</div>
            </div>
        </div>
        <div class="flex space-x-2 justify-between">
            <div class="">สต๊อกคงเหลือ</div>
            <div class="font-semibold text-sky-600">{{productShopDetail.summary | number}} ชิ้น</div>
        </div>
        <div class="grid grid-cols-2 gap-2 items-center">
            <div class="">สต๊อกขั้นต่ำ</div>
            @if (isEditingStock){
            <form [formGroup]="stockForm" class="relative">
                <input type="number" class="input !pr-12"
                    [ngClass]="{ 'input-invalid': stockForm.controls['min_stock'].errors }" formControlName="min_stock"
                    min="0">
                <div class="absolute right-4 -mt-9 text-gray-400">ชิ้น</div>
            </form>
            }
            @else {
            <div class="font-semibold text-right">{{productShopDetail.min_stock}} ชิ้น</div>
            }
        </div>
        @if (isEditingStock) {
        <div class="flex justify-end items-center pt-6 w-full">
            <div class="grid grid-cols-2 lg:flex gap-2 w-full">
                <button class="btn-secondary w-full text-base" (click)="resetEditStock()">
                    ยกเลิก
                </button>
                <button class="btn-primary w-full text-base" (click)="updateStock()">
                    อัพเดต
                </button>
            </div>
        </div>
        }
    </div>
</ng-template>

<ng-template #detailDefaultProductTemplate>
    <div class="card w-full space-y-2">
        <div class="pb-4">
            <div class="text-base">
                <span class="font-semibold">{{productDetail.name}}</span>
                <span> - </span>
                <span class="text-primary-500 font-semibold">{{sku}}</span>
            </div>
        </div>
        <div class="flex space-x-2 justify-between">
            <div class="font-normal">หมวดหมู่</div>
            <div class="">{{productDetail?.category?.name}}</div>
        </div>
        <div class="flex space-x-2 justify-between">
            <div class="font-normal">แบรนด์</div>
            <div class="">{{productDetail?.brand?.name}}</div>
        </div>
        <div class="flex space-x-2 justify-between">
            <div class="font-normal">ระยะเวลารับประกัน</div>
            <div *ngIf="productDetail.warranty"
                class="bg-blue-50 text-blue-600 text-sm px-2 rounded flex items-center space-x-2">
                <fa-icon [icon]="faShield" class=""></fa-icon>
                <span> รับประกัน {{formatWarranty(productDetail.warranty)}}</span>
            </div>
        </div>
        <div class="flex space-x-2 justify-between">
            <div class="font-normal">รายละเอียด</div>
            <a class="text-secondary-500 hover:underline flex space-x-1 items-center" target="_blank"
                href="https://www.remaxthailand.co.th/product/{{sku}}">
                <div class="">ดูรายละเอียด</div>
                <fa-icon [icon]="faExternalLink" class="text-sm "></fa-icon>
            </a>
        </div>
    </div>
</ng-template>