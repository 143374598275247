import { Component, OnInit } from '@angular/core';
import { faBarcode, faCartPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StockOutApiService } from '../../services/api/stock-out-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-stock-out-create',
  templateUrl: './stock-out-create.component.html',
  styleUrl: './stock-out-create.component.css'
})
export class StockOutCreateComponent implements OnInit {
  faCartPlus = faCartPlus;
  faTrash = faTrash;
  faBarcode = faBarcode;

  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopName: string = this.authService.selectedShop?.name || '';

  mode: string = 'add'; // add, delete
  items: any[] = []

  serialNoDelete: any;
  serialNoAdd: any;

  stockOutDetail: any = {};
  stockOutNo: string = '';

  isCreating: boolean = false;

  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private stockOutApiService: StockOutApiService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.route.queryParamMap.subscribe(async params => {
      this.stockOutNo = params.get('stock_out_no') || '';
      if (this.stockOutNo) {
        this.stockOutApiService.getOneStockOut(this.stockOutNo).then((res: any) => {
          console.log(res)
          if (res.success && res.data) {
            this.stockOutDetail = res.data;
            this.items = res.data.items;
          }
        })
      }
    });
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 10)
  }

  formatProductDisplay(): any[] {
    let result: any[] = [];
    for (const item of this.items.reverse()) {
      let index = result.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result.push({
          ...item,
          qty: 1
        });
      } else {
        result[index].qty += 1;
      }
    }
    return result;
  }

  modeToggle(value: string) {
    this.mode = value;

    if (this.mode === 'add') {
      setTimeout(() => {
        document.getElementById('barcode-add')?.focus()
      }, 10)
    } else {
      setTimeout(() => {
        document.getElementById('barcode-delete')?.focus()
      }, 10)
    }
  }

  async onAdd(event: any) {

    const serialNo = event.target.value;

    if (this.isCreating) {
      console.log('Sorry, Creating stock out');
      return;
    }

    if (serialNo) {
      this.isCreating = true;
      
      if (this.items.find(e => e.serial_no === serialNo)) {
        this.modalService.openModal('info', 'Ooop!', 'มี barcode นี้ในรายการคืนสำนักงานแล้ว').then(() => { })
        this.clearInputSerialNoAdd();
        return;
      }

      if (this.stockOutNo) {
        await this.addStockOutItem(serialNo);
      } else {
        await this.createStockOut(serialNo);
      }

      this.clearInputSerialNoAdd();

    } else {
      this.modalService.openModal('fail', 'กรุณากรอก Barcode', '')
    }

  }

  async addStockOutItem(serialNo: string) {
    const data = {
      serial_no: serialNo,
      shop_id: this.shopId
    }
    const result: any = await this.stockOutApiService.addStockOutItem(this.stockOutNo, data)
    if (result.success) {
      this.items = result.data.items;
    } else {
      let message = '';

      if (result.message === 'not found serial') {
        message = 'ไม่พบ barcode นี้ในระบบ กรุณาตรวจสอบ barcode ใหม่อีกครั้ง'
      } else if (result.message === 'can not get serial') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลบาร์โค้ด กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'not found stock out') {
        message = 'ไม่พบรายการคืนสำนักงานในระบบ'
      } else if (result.message === 'can not get stock out') {
        message = 'เกิดข้อผิดพลาดในการโหลดรายการคืนสำนักงาน กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'not found product') {
        message = 'ไม่พบสินค้านี้ในระบบ กรุณาตรวจสอบ barcode ใหม่อีกครั้ง'
      } else if (result.message === 'serial number is sold') {
        message = 'สินค้านี้ขายไปแล้ว ไม่สามารถคืนสำนักงานได้'
      } else if (result.message === 'serial number is claim') {
        message = 'สินค้านี้อยู่ในขั้นตอนเคลม ไม่สามารถคืนสำนักงานได้'
      } else if (result.message === 'serial number is out') {
        message = 'สินค้านี้อยู่ในรายการคืนสำนักงานแล้ว ไม่สามารถทำรายการซ้ำได้'
      } else if (result.message === 'shop id not match') {
        message = 'ไม่ใช่สินค้าในร้านนี้ ไม่สามารถคืนสำนักงานได้'
      } else if (result.message === 'stock out status was completed') {
        message = 'รายการคืนสำนักงานนี้สำเร็จแล้ว ไม่สามารถแก้ไขได้'
      } else if (result.message === 'stock out status was waiting_confirm') {
        message = 'รายการคืนสำนักงานนี้อยู่ในขั้นตอนรอยืนยัน ไม่สามารถแก้ไขได้'
      } else if (result.message === 'stock out status was canceled') {
        message = 'รายการคืนสำนักงานนี้ยกเลิกแล้ว ไม่สามารถแก้ไขได้'
      }

      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
    }
  }

  async createStockOut(serialNo: string) {
    const dataCreate = {
      shop_id: this.shopId,
      shop_name: this.shopName,
      serial_no: serialNo
    }
    const result: any = await this.stockOutApiService.createOrderStockOut(dataCreate)
    if (result.success) {
      this.items = result.data.items;
      this.stockOutNo = result.data.stock_out_no;
    } else {
      let message = '';

      if (result.message === 'not found serial') {
        message = 'ไม่พบ barcode นี้ในระบบ กรุณาตรวจสอบ barcode ใหม่อีกครั้ง'
      } else if (result.message === 'not found product') {
        message = 'ไม่พบสินค้านี้ในระบบ กรุณาตรวจสอบ barcode ใหม่อีกครั้ง'
      } else if (result.message === 'can not get serial') {
        message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลบาร์โค้ด กรุณาลองใหม่อีกครั้ง'
      } else if (result.message === 'serial number is sold') {
        message = 'สินค้านี้ขายไปแล้ว ไม่สามารถคืนสำนักงานได้'
      } else if (result.message === 'serial number is claim') {
        message = 'สินค้านี้อยู่ในขั้นตอนเคลม ไม่สามารถคืนสำนักงานได้'
      } else if (result.message === 'serial number is out') {
        message = 'สินค้านี้อยู่ในรายการคืนสำนักงานแล้ว ไม่สามารถทำรายการซ้ำได้'
      } else if (result.message === 'shop id not match') {
        message = 'ไม่ใช่สินค้าในร้านนี้ ไม่สามารถคืนสำนักงานได้'
      }

      this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
    }
  }

  async onDelete(event: any) {

    const serialNo = event.target.value;

    if (serialNo) {
      if (!this.items.find(e => e.serial_no === serialNo)) {
        this.modalService.openModal('info', 'Ooop!', 'ไม่พบ barcode นี้ในรายการคืนสำนักงาน').then(() => { })
        this.clearInputSerialNoDelete();
        return;
      }

      const body = { serial_no: serialNo }
      const result: any = await this.stockOutApiService.removeStockOutItem(this.stockOutNo, body);
      if (result.success) {
        this.items = result.data.items;
      } else {

        let message = '';

        if (result.message === 'can not get stock out') {
          message = 'เกิดข้อผิดพลาดในการโหลดรายการคืนสำนักงาน กรุณาลองใหม่อีกครั้ง'
        } else if (result.message === 'stock out not found') {
          message = 'เกิดข้อผิดพลาด ไม่พบรายการคืนสำนักงาน'
        } else if (result.message === 'serial not found in stock out') {
          message = 'ไม่สามารถลบได้ ไม่พบ barcode ในรายการคืนสำนักงาน'
        } else if (result.message === 'stock out status was completed') {
          message = 'รายการคืนสำนักงานนี้สำเร็จแล้ว ไม่สามารถแก้ไขได้'
        } else if (result.message === 'stock out status was waiting_confirm') {
          message = 'รายการคืนสำนักงานนี้อยู่ในขั้นตอนรอยืนยัน ไม่สามารถแก้ไขได้'
        } else if (result.message === 'stock out status was canceled') {
          message = 'รายการคืนสำนักงานนี้ยกเลิกแล้ว ไม่สามารถแก้ไขได้'
        }

        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
      }
      this.clearInputSerialNoDelete();

    } else {
      this.modalService.openModal('fail', 'กรุณากรอก Barcode', '')
    }

  }

  clearInputSerialNoAdd() {
    this.serialNoAdd = null;
    this.isCreating = false;
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 10)
  }

  clearInputSerialNoDelete() {
    this.serialNoDelete = null;
    setTimeout(() => {
      document.getElementById('barcode-delete')?.focus()
    }, 10)
  }

  callBackConfirm = (event: any) => {
    if (this.items.length <= 0) {
      this.modalService.openModal('info', 'กรุณาเพิ่มรายการสินค้า', '')
      return;
    }
    if (event.confirm) {
      this.modalService.openModal('confirm', 'บันทึกรายการโอนคืนสินค้า', 'หากบันทึกแล้วจะไม่สามารถแก้ไขได้ ต้องการบันทึกรายการโอนคืนสินค้าหรือไม่?').then(async (result: any) => {
        if (result) {
          this.stockOutApiService.updateStockOutWaiting(this.stockOutNo).then((res: any) => {
            if (res.success) {
              this.modalService.openModal('success', 'สำเร็จ', 'บันทึกรายการโอนคืนสินค้าสำเร็จ').then(() => {
                this.router.navigate(['/stock-out'])
              })
            } else {
              this.modalService.openModal('fail', 'ไม่สำเร็จ', 'ไม่สามารถบันทึกรายการโอนคืนสินค้าได้');
            }
          })
        }
      })
    }
  }


}
