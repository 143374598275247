import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../core/services/api/api.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class InventoryApiService {
  // private inventory_endpoint = environment.inventory_endpoint;
  // private inventory_token = environment.inventory_token;

  private endpoint = environment.endpoint;


  private databaseApp: firebase.app.App;
  private database: firebase.database.Database;

  constructor(
    private http: HttpClient,
    // private db: AngularFireDatabase,
    private apiService: ApiService
  ) {

    this.databaseApp = firebase.app('CLAIM_AUTH');
    this.database = this.databaseApp.database();
  }

  getData(shopId: string): Observable<any[]> {
    // return this.db.list(`/data/inventory/8VDPZQGLKCDTVJXH/${shopId}/summary`).valueChanges();
    const dataPath = `/data/inventory/8VDPZQGLKCDTVJXH/${shopId}/summary`;
    const ref = this.database.ref(dataPath);

    return new Observable<any[]>(observer => {
      ref.on('value', snapshot => {
        console.log(snapshot.val())
        const data = snapshot.val();
        observer.next(data ? Object.values(data) : []);
      }, error => {
        observer.error(error);
      });

      // Cleanup function to remove the listener when subscription is unsubscribed
      return () => ref.off();
    });
  }

  // getAPIHeader(): any {
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${this.inventory_token}`
  //   }
  //   return headers
  // }

  getProductBySerialNo(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/inventory/product/info`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  // getInventory(shopId: string) {
  //   const endPoint = this.inventory_endpoint;
  //   // const headers = this.getAPIHeader();
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${this.inventory_token}`
  //   }
  //   return new Promise((resolve, reject) => {
  //     return this.http.get(`${endPoint}/stock/${shopId}`, { headers, withCredentials: true }).subscribe({
  //       next: (data) => {
  //         resolve(data);
  //       },
  //       error: (error) => {
  //         console.log(error)
  //         console.error(error);
  //         resolve({ success: false, message: error?.error?.error?.message || '' });
  //       }
  //     });
  //   })
  // }

  getInventory(shopId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/inventory/stock/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  // createInventory(shopId: string, body: any) {
  //   const endPoint = this.inventory_endpoint;
  //   const headers = this.getAPIHeader();
  //   return new Promise((resolve, reject) => {
  //     return this.http.put(`${endPoint}/stock/${shopId}`, body, { headers, withCredentials: true }).subscribe({
  //       next: (data) => {
  //         resolve(data);
  //       },
  //       error: (error) => {
  //         console.error(error);
  //         resolve({ success: false, message: error?.error?.error?.message || '' });
  //       }
  //     });
  //   })
  // }

  createInventory(shopId: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.put(`${endPoint}/inventory/stock/${shopId}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  // inventoryCounting(shopId: string, body: any) {
  //   const endPoint = this.inventory_endpoint;
  //   const headers = this.getAPIHeader();
  //   return new Promise((resolve, reject) => {
  //     return this.http.patch(`${endPoint}/stock/${shopId}`, body, { headers }).subscribe({
  //       next: (data) => {
  //         resolve(data);
  //       },
  //       error: (error) => {
  //         console.error(error);
  //         resolve({ success: false, message: error?.error?.error?.message || '' });
  //       }
  //     });
  //   })
  // }

  inventoryCounting(shopId: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/inventory/stock/${shopId}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  // getProductTransaction(serialNo: string) {
  //   const endPoint = this.inventory_endpoint;
  //   const headers = this.getAPIHeader();
  //   return new Promise((resolve, reject) => {
  //     return this.http.get(`${endPoint}/product/transaction/${serialNo}`, { headers }).subscribe({
  //       next: (data) => {
  //         resolve(data);
  //       },
  //       error: (error) => {
  //         console.error(error);
  //         resolve({ success: false, message: error?.error?.error?.message || '' });
  //       }
  //     });
  //   })
  // }

  getProductTransaction(serialNo: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/inventory/product/transaction/${serialNo}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  // deleteInventory(shopId: string) {
  //   const endPoint = this.inventory_endpoint;
  //   const headers = {
  //     'accept': 'application/json',
  //     'Authorization': `Bearer ${this.inventory_token}`
  //   }
  //   return new Promise((resolve, reject) => {
  //     return this.http.delete(`${endPoint}/stock/${shopId}`, { headers }).subscribe({
  //       next: (data) => {
  //         resolve(data);
  //       },
  //       error: (error) => {
  //         console.error(error);
  //         resolve({ success: false, message: error?.error?.error?.message || '' });
  //       }
  //     });
  //   })
  // }

  deleteInventory(shopId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.delete(`${endPoint}/inventory/stock/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getActiveSerialByShop(shopId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/serial/shop/${shopId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getReceiptByshop(shopId: string, filters: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/receipt/${shopId}?${this.apiService.parseFilters(filters)}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductShopByshop(shopId: string, filters: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/product-shop/${shopId}?${this.apiService.parseFilters(filters)}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  confirmInventory(body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${endPoint}/products/inventory`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getInventoryHistory(shopId: string, filters: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/inventories/${shopId}?${this.apiService.parseFilters(filters)}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getInventoryDetail(inventoryId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/inventory/${inventoryId}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  confirmInventoryComplete(inventoryId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/products/inventory/${inventoryId}/complete`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateInventoryCountSerial(inventoryId: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/products/inventory/${inventoryId}/counted`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getInventoryReport(inventoryId: string) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/inventory/${inventoryId}/report`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  syncStockProduct(shopId: string, body: any) {
    const endPoint = this.endpoint;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/products/shop/${shopId}/sync-stock`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
