<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                รายละเอียดสินค้าคืนสำนักงาน
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            @if(serials.length > 0){
            <div class="space-y-5">

                <div class="flex flex-col justify-center items-center w-full">
                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + serials[0].sku + '/1.jpg'"
                        [alt]="serials[0].sku" class="w-48 h-48 rounded-lg border"
                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                </div>
                <div>
                    <div class="text-gray-500"> สินค้า </div>
                    <div> {{serials[0].name}} </div>
                </div>
                <div>
                    <div class="text-gray-500"> SKU </div>
                    <div> {{serials[0].sku}} </div>
                </div>
                <div>
                    <div class="text-gray-500"> Serial Number </div>
                    <ul class="list-disc pl-6">
                        @for (serial of serials; track $index) {
                        <li> {{serial.serial_no}} </li>
                        }
                    </ul>
                </div>

                <div class="h-32"></div>
            </div>
            }
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="w-full grid grid-cols-1 gap-2 pt-2">
                <button class="btn-secondary w-full" (click)="close()">ปิด</button>
            </div>
        </div>
    </div>
</div>