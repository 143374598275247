import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { ClaimService } from '../../services/claim/claim.service';
import { MatDialog } from '@angular/material/dialog';
import { ClaimCustomerDialogComponent } from '../../components/claim-customer-dialog/claim-customer-dialog.component';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrl: './claim.component.css'
})
export class ClaimComponent implements OnInit {
  faArrowDown = faArrowDown;

  liffUrlClaim = environment.liff_url_claim;

  items: any[] = []
  offset: number = 0;
  limit: number = 10;
  countClaim: any = {
    'ongoing': 0,
    'success': 0
  }
  state = 'ongoing';  // ongoing, success

  shopId: string = this.authService.selectedShop?.shop_id || '';

  showDetail = false;
  claimDetail: any = {}

  isLoaded = false;
  loadingMore = false
  statusGetClaim: any = {
    ongoing: 'pending',
    success: 'success'
  }

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private claimService: ClaimService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    this.reloadData()
  }

  async reloadData() {
    this.isLoaded = false;
    if (this.state === 'ongoing') {
      this.items = await this.getOrderList(this.statusGetClaim.ongoing);
      this.countClaim['success'] = await this.getCountClaim(this.statusGetClaim.success)
    } else {
      this.items = await this.getOrderList(this.statusGetClaim.success);
      this.countClaim['ongoing'] = await this.getCountClaim(this.statusGetClaim.ongoing)
    }
    console.log(this.countClaim);
    this.isLoaded = true;
  }

  getClaimDetail(filter: any) {
    return this.claimService.getClaim(this.shopId, filter).then((response: any) => {
      if (response.success) {
        return response.data.sort((a: any, b: any) => b.created.date - a.created.date)
      } else {
        return []
      }
    })
  }

  async getOrderList(status: string): Promise<any[]> {
    const filter = {
      status,
      offset: this.offset,
      limit: this.limit,
      fields: 'claim_ref,claim_id,product,response,created,is_receipt'
    }
    return this.claimService.getClaim(this.shopId, filter).then((response: any) => {
      if (!response.success) {
        this.countClaim[this.state] = 0
        return [];
      }
      this.countClaim[this.state] = response.count
      return response.data;
    })
  }

  getCountClaim(status: string) {
    const filter = {
      status,
      offset: 0,
      limit: 1,
      fields: 'claim_id'
    }
    return this.claimService.getClaim(this.shopId, filter).then((response: any) => {
      console.log(response);
      if (!response.success) return 0;
      return response.count
    })
  }

  async loadMore() {
    this.loadingMore = true
    this.offset = this.offset + this.limit;
    let orderResponse = [];
    if (this.state === 'ongoing') {
      orderResponse = await this.getOrderList(this.statusGetClaim.ongoing);
    } else {
      orderResponse = await this.getOrderList(this.statusGetClaim.success);
    }
    this.items.push(...orderResponse);
    this.loadingMore = false
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  async clickShowDetail(claim_id: string) {
    console.log(claim_id)
    const filter = {
      claim_id: claim_id
    }
    const fetchClaim = await this.getClaimDetail(filter)
    this.claimDetail = fetchClaim[0]
    this.showDetail = true;
  }

  callBackClaimDetail = (data: any) => {
    console.log(data)

    if (data.followUp) {
      console.log(`${this.liffUrlClaim}/follow-up?id=${this.claimDetail.claim_id}&shop-id=${this.shopId}`)
      const dialogRef = this.dialog.open(ClaimCustomerDialogComponent, {
        data: { qrcode: `${this.liffUrlClaim}/follow-up?id=${this.claimDetail.claim_id}&shop-id=${this.shopId}` }
      });
    } else {
      this.showDetail = false;
    }
  }

  displayStatus(status: string, is_receipt: any) {
    /**
      S1 =	ส่ง SUP
      S2 =	ส่งช่าง
      S3 =	รอสินค้า (Remax)
      S4 =	รอสินค้า (ซัพนอก)
      S5 =	รอดำเนินการ
      S6 =	ปิดงาน
      S7 =	อื่นๆ
    */

    if (status === 'ปิดงาน') {
      if (is_receipt) {
        return 'เคลมสำเร็จ'
      } else {
        return 'รอร้านค้ารับเข้า'
      }
    }
    return status
  }

  colorStatus(status: string, is_receipt: any) {
    /**
      S1 =	ส่ง SUP
      S2 =	ส่งช่าง
      S3 =	รอสินค้า (Remax)
      S4 =	รอสินค้า (ซัพนอก)
      S5 =	รอดำเนินการ
      S6 =	ปิดงาน
      S7 =	อื่นๆ
    */

    if (status === 'ปิดงาน') {
      if (is_receipt) {
        return '!bg-lime-200'
      } else {
        return '!bg-orange-300'
      }
    }
    return status
  }

  changeState(state: string) {
    if (this.state !== state) {
      this.state = state;
      this.offset = 0;
      this.reloadData();
    }
  }

}
