import { Injectable } from '@angular/core';
import { ClaimApiService } from '../api/claim-api.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  constructor(
    private claimApiService: ClaimApiService
  ) { }

  async getClaim(shopId: string, filters: any) {
    return this.claimApiService.getClaim(shopId, filters).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  async checkProductWarraty(barcode: string) {
    return this.claimApiService.checkProductWarraty(barcode).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  async createClaimProduct(body: any) {
    return this.claimApiService.createClaimProduct(body).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  getShopByShopId(shopId: string) {
    return this.claimApiService.getShopByShopId(shopId).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }

  validateClaimSerial(serialNo: string) {
    return this.claimApiService.validateClaimSerial(serialNo).then((response: any) => {
      console.log(response)
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.error ? response.error.message : response.message }
      }
    })
  }
}
